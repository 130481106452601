import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph } from "../../..";
import { DocPageMeta, InlineMessage } from "../../../../../src/docs";
import { ContentSection, HeaderSection } from "../../../../../src/docs/sections";
import { Icon } from "./icons";
import * as Icons from "./icons-list";
import * as scss from "./icons-overview.scss";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Icons Overview",
	notepad: "https://codeshare.io/amqmgb",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="Icons Overview" subTitle="An overview of all icons available for use." />
		<ContentSection>
			<InlineMessage variant="info">
				Can't find the <GatsbyLink to="/lib/components/Visuals/Icon">icon</GatsbyLink> you're
				looking for? Consult the <GatsbyLink to="/Designing/icons-FAQ">Icons FAQ</GatsbyLink> page
				to find out what to do.
			</InlineMessage>
			<div className={scss.layout}>
				{Object.entries(Icons).map(([key, iconComp]) => (
					<div key={key} className={scss.container}>
						<Icon>{React.createElement(iconComp)}</Icon>
						<Paragraph size="xSmall">{key}</Paragraph>
					</div>
				))}
			</div>
		</ContentSection>
	</>
);
